export default {
  data() {
    return {
      systime: new Date(),
      systimeInterval: null,
    };
  },
  computed: {
    whiteLabel: () => localStorage.getItem('white-label') === 'true',
  },
  created() {
    this.systimeInterval = setInterval(() => {
      this.systime = new Date();
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.systimeInterval);
  },
};
