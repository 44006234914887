import { useCartStore } from '~/stores/cart.js';
export default class Cart {
  constructor() {
    this.cart = useCartStore();
  }

  getPlanDetails() {
    return this.cart.getPlanDetails;
  }

  getHasPlan() {
    return this.cart.getHasPlan;
  }

  getPurchaseData() {
    return this.cart.getPurchaseData;
  }

  getHasPurchaseData() {
    return this.cart.getHasPurchaseData;
  }

  setPurchaseData(value) {
    this.cart.setPurchaseData(value);
  }

  setPlanDetails(value) {
    this.cart.setPlanDetails(value);
  }
}
