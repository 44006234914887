import { defineStore } from 'pinia';

export const useListsStore = defineStore({
  id: 'lists',
  state: () => ({
    bulkUpdatePath: '',
    listPath: '',
    data: [],
    rowsSelected: [],
  }),
  getters: {
    getBulkUpdatePath: (state) => {
      return state.bulkUpdatePath;
    },
    getListPath: (state) => {
      return state.listPath;
    },
    getData: (state) => {
      return state.data;
    },
    getRowsSelected: (state) => {
      return state.rowsSelected;
    },
    getCurrentState: (state) => {
      return {
        bulkUpdatePath: state.bulkUpdatePath,
        listPath: state.listPath,
        data: state.data,
        rowsSelected: state.rowsSelected,
      };
    },
  },
  actions: {
    setBulkUpdatePath(value) {
      this.bulkUpdatePath = value;
    },
    setPath(value) {
      this.listPath = value;
    },
    setData(value) {
      this.data = value;
    },
    setRowsSelected(value) {
      this.rowsSelected = value;
    },
    setCurrentState(value) {
      this.bulkUpdatePath = value.bulkUpdatePath;
      this.listPath = value.listPath;
      this.data = value.data;
      this.rowsSelected = value.rowsSelected;
    },
    resetCurrentState() {
      this.bulkUpdatePath = '';
      this.listPath = '';
      this.data = [];
      this.rowsSelected = [];
    },
  },
});
