import { useStampingsStore } from '@/stores/stampings';
export default class Stampings {
  constructor() {
    this.stampings = useStampingsStore();
  }

  getPeriodData() {
    return this.stampings.getPeriodData;
  }

  getHasPeriodData() {
    return this.stampings.getHasPeriodData;
  }

  setPeriodData(value) {
    this.stampings.setPeriodData(value);
  }
}
