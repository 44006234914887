import { defineStore } from 'pinia';

export const usePreferencestore = defineStore({
  id: 'preferences',
  state: () => ({
    isExpandedMenu: !!+localStorage?.isExpandedMenu,
    isFixedMenu: !!+localStorage?.isFixedMenu,
    showRouteOverlay: false,
  }),
  getters: {
    getIsExpandedMenu: (state) => {
      return state.isExpandedMenu;
    },
    getIsFixedMenu: (state) => {
      return state.isFixedMenu;
    },
    getShowRouteOverlay: (state) => {
      return state.showRouteOverlay;
    },
  },
  actions: {
    toggleMenu() {
      this.isExpandedMenu = !this.isExpandedMenu;
      this.isFixedMenu = !!this.isExpandedMenu;
      localStorage.isExpandedMenu = this.isExpandedMenu ? 1 : 0;
      localStorage.isFixedMenu = this.isFixedMenu ? 1 : 0;
    },
    toggleOverlay() {
      this.showRouteOverlay = !this.showRouteOverlay;
    },
    setOverlay(value) {
      this.showRouteOverlay = value;
    },
    setMenuStateOnHover(value) {
      this.isExpandedMenu = value;
    },
  },
});
