export default {
  data() {
    return {
      inactivityAllowTime: 15 * 60 * 1000, // 15 minutes in milliseconds
      // inactivityAllowTime: 10 * 1000,
      intervalTime: 1000, // 1 second in milliseconds
      checkIdleInterval: null,
      countdownInterval: null,
      showInactivityModal: false,
      localStorageIdleFlag: 'zentric-idle',
      localStorageLastActivity: 'zentric-last-activity',
      localStorageCountdown: 'zentric-countdown',
      countdownTime: 120, // 2 minutes in seconds
      idleTimer: '02:00 min.',
      onClickHandler: null,
      onMouseMoveHandler: null,
      onKeyDownHandler: null,
      onStorageHandler: null,
      validateOnInactivity: false,
    };
  },
  watch: {
    showInactivityModal(current, old) {
      if (current && !old) {
        this.timeCountdown();
      }
    },
    countdownTime(current) {
      if (current <= 0 && this.showInactivityModal) {
        this.deleteSession();
      }
    },
  },
  mounted() {
    this.validateSession();
    this.setTimeStamp();
    this.onMountData();
    this.addEventListeners();
    this.addCheckIdleInterval();
  },
  beforeDestroy() {
    this.clearAllIntervals();
    this.removeAllEventListeners();
    localStorage.setItem(this.localStorageIdleFlag, false);
  },
  methods: {
    validateSession() {
      const isAuth = this.$authentication.getSession();

      if (isAuth) {
        return;
      }

      this.removeAllEventListeners();
      this.clearAllIntervals();

      const isLayoutsAppUser =
        this.$authentication.getUserConfigByVariable('layouts')?.value === '1';

      const whiteLabel = localStorage.getItem('white-label') === 'true';

      const redirectPath = isLayoutsAppUser
        ? '/layout-app/login/'
        : whiteLabel
          ? '/auth/'
          : '/login/';

      this.$router.push(redirectPath);
    },
    setTimeStamp() {
      localStorage.setItem(
        this.localStorageLastActivity,
        new Date().getTime() + this.inactivityAllowTime,
      );
      // Si ya existe el local storage con este valor no debe modificarse por eventos de actividad
      const current = localStorage.getItem(this.localStorageIdleFlag);
      if (!current) {
        localStorage.setItem(this.localStorageIdleFlag, false);
      }
    },
    onMountData() {
      this.showInactivityModal =
        localStorage.getItem(this.localStorageIdleFlag) === 'true';
      if (this.showInactivityModal) {
        clearInterval(this.countdownInterval);
        const localStorageCountdown = localStorage.getItem(
          this.localStorageCountdown,
        );
        this.countdownTime = localStorageCountdown ?? this.countdownTime;
        return;
      }

      localStorage.setItem(this.localStorageCountdown, this.countdownTime);
    },
    addEventListeners() {
      this.onClickHandler = this.setTimeStamp.bind(this);
      this.onMouseMoveHandler = this.setTimeStamp.bind(this);
      this.onKeyDownHandler = this.setTimeStamp.bind(this);
      this.onStorageHandler = this.getLocalStorageIdleFlag.bind(this);

      document.addEventListener('click', this.onClickHandler);
      document.addEventListener('mousemove', this.onMouseMoveHandler);
      document.addEventListener('keydown', this.onKeyDownHandler);
      window.addEventListener('storage', this.onStorageHandler);
    },
    removeAllEventListeners() {
      document.removeEventListener('click', this.onClickHandler);
      document.removeEventListener('mousemove', this.onMouseMoveHandler);
      document.removeEventListener('keydown', this.onKeyDownHandler);
      window.removeEventListener('storage', this.onStorageHandler);
    },
    addCheckIdleInterval() {
      this.checkIdleInterval = setInterval(() => {
        this.checkIdleTime();
      }, this.intervalTime);
    },
    resetInitData() {
      this.countdownTime = 120;
      this.idleTimer = '02:00 min.';
    },
    clearAllIntervals() {
      clearInterval(this.checkIdleInterval);
      clearInterval(this.countdownInterval);
    },
    getLocalStorageIdleFlag() {
      this.showInactivityModal =
        localStorage.getItem(this.localStorageIdleFlag) === 'true';
    },
    checkIdleTime() {
      const isLoadingPlatform = this.$platform.getLoading();
      if (isLoadingPlatform) {
        return;
      }
      const lastActivity = localStorage.getItem(this.localStorageLastActivity);
      const currentTime = new Date().getTime();
      if (currentTime >= lastActivity) {
        localStorage.setItem(this.localStorageIdleFlag, true);
        this.showInactivityModal = true;
      }
    },
    timeCountdown() {
      localStorage.setItem(this.localStorageCountdown, this.countdownTime);
      let minutes = '';
      let seconds = '';
      if (this.countdownInterval) clearInterval(this.countdownInterval);
      this.countdownInterval = setInterval(() => {
        document.onclick = () => {};
        document.onmousemove = () => {};
        document.onkeydown = () => {};
        clearInterval(this.checkIdleInterval);
        minutes = parseInt(this.countdownTime / 60, 10);
        seconds = parseInt(this.countdownTime % 60, 10);

        minutes = minutes < 10 ? `0${minutes}` : minutes;
        seconds = seconds < 10 ? `0${seconds}` : seconds;

        this.idleTimer = `${minutes}:${seconds} min.`;
        this.countdownTime--;
        localStorage.setItem(this.localStorageCountdown, this.countdownTime);
      }, 1000);
    },
    async restoreSession(password) {
      this.validateOnInactivity = true;
      const { username } = this.$authentication.getSession()?.user || {};
      const { successfull = false } = await this.login({ username, password });

      if (successfull) {
        this.showInactivityModal = false;
        localStorage.setItem(this.localStorageIdleFlag, false);

        this.restartIntervalsAndInitData();
      }

      this.validateOnInactivity = false;
    },
    restartIntervalsAndInitData() {
      this.clearAllIntervals();
      this.resetInitData();
      this.setTimeStamp();
      this.addCheckIdleInterval();
    },
    async deleteSession(isAutoClose = true) {
      const { successfull = false } = await this.logout(null, isAutoClose);

      if (!successfull) {
        return;
      }

      this.showInactivityModal = false;
      localStorage.removeItem(this.localStorageIdleFlag);
      localStorage.removeItem(this.localStorageCountdown);
      this.clearAllIntervals();
      this.removeAllEventListeners();
    },
  },
};
