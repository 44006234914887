export default async function ({ app, route, store, redirect }) {
  const whiteLabel = localStorage.getItem('white-label') === 'true';
  const isLayoutsAppUser =
    app.$authentication.getUserConfigByVariable('layouts')?.value === '1';

  const fallbackRedirectUrl = isLayoutsAppUser
    ? '/layouts-app/login/'
    : whiteLabel
      ? '/auth/'
      : '/login/';
  const licenseInvalidRedirectUrl = isLayoutsAppUser
    ? '/layouts-app/login/'
    : whiteLabel
      ? '/auth/?redirect=%2F'
      : '/cart/';

  const { path = '' } = route || {};
  const excludePath = [
    '/cart',
    '/helps',
    '/tickets',
    '/historic-load/cfdi/employees/',
    '/historic-load/cfdi/concepts/',
  ].some((excluded) => path.includes(excluded));
  const isWorky = app.$authentication.isWorky();

  // Do not validate in the following cases:
  // - The route is within the exclusions
  // - The account is Worky+
  // - The account is a layout-app user
  if (excludePath || isWorky || isLayoutsAppUser) return;

  /*
  License Expiration Date Validation

  This section of code performs a validation check on the expiration date of the application's license.

  If the license expiration is deemed invalid, it takes the following actions:
  - Retrieves validation response from the app's license module.
  - If the validation response is not valid:
    - Retrieves redirectPath and error details from the response.
    - Creates a new error toast notification with the provided message and description.
    - Redirects the user to the specified path (fallback or invalid license) based on the redirectPath.
    - Resets the user's session if the redirectPath is set to fallback (1).
  
  Note: This assumes the presence of 'app.$license' for accessing license-related functionalities.
*/
  const expirationResponse = app.$license?.expirationDateValidation();

  if (!expirationResponse?.isValid) {
    const { redirectPath = null, response } = expirationResponse || {};
    const {
      message = 'No se pudo validar la licencia.',
      description = 'No fue posible obtener datos de la licencia para su validación, por favor intenta más tarde.',
    } = response || {};

    // Create a new error toast notification
    const newToast = {
      message,
      description,
      type: 'error',
    };
    app.$toasts.createToast(newToast);

    // Redirect the user based on the specified path
    const targetPath =
      redirectPath === 1 ? fallbackRedirectUrl : licenseInvalidRedirectUrl;
    redirect(targetPath);

    // Reset user's session if the redirect path is set to fallback (1)
    if (redirectPath === 1) app.$authentication.resetSession();
  }

  /*
  Employees Validation

  This section of code performs a validation check on the number of employees allowed by the application's license.

  If the employee validation is not valid, it takes the following actions:
  - Retrieves validation response from the app's license module.
  - If the validation response is not valid:
    - Retrieves redirectPath and error details from the response.
    - Creates a new error toast notification with the provided message and description.
    - Redirects the user to the specified path (fallback or invalid license) based on the redirectPath.
    - Resets the user's session if the redirectPath is set to fallback (1).
  
  Note: This assumes the presence of 'app.$license' for accessing license-related functionalities.
*/

  const employeesResponse = await app.$license.licenseEmployeesValidation();

  if (employeesResponse?.isValid) {
    // No further action needed if employees validation is valid
    return;
  }

  const { redirectPath = null, response } = employeesResponse || {};
  const {
    message = 'Error en la validación de trabajadores.',
    description = 'No fue posible validar el número de trabajadores permitidos en la licencia.',
  } = response || {};

  // Create a new error toast notification
  const newToast = {
    message,
    description,
    type: 'error',
  };
  app.$toasts.createToast(newToast);

  // Redirect the user based on the specified path
  const targetPath =
    redirectPath === 1 ? fallbackRedirectUrl : licenseInvalidRedirectUrl;
  redirect(targetPath);

  // Reset user's session if the redirect path is set to fallback (1)
  if (redirectPath === 1) app.$authentication.resetSession();
}
