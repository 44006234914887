<template>
  <div>
    <div
      class="flex h-[calc(100vh-160px)] items-center justify-center dark:bg-worky-neutral-400"
    >
      <Forbidden v-if="error.statusCode === 403" />
      <Error
        v-else
        :title="getMessageError.title"
        :message="getMessageError.message"
      />
    </div>
  </div>
</template>
<script>
  export default {
    name: 'ErrorLayout',
    components: {
      Forbidden: () => import('~/components/screens/errors/Forbidden.vue'),
      Error: () => import('~/components/screens/errors/Error.vue'),
    },
    props: {
      error: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        homePath:
          this.$authentication.getUserConfigByVariable('layouts')?.value === '1'
            ? '/layouts-app/'
            : '/',
      };
    },
    computed: {
      getMessageError() {
        if (this.error.statusCode === 404)
          return {
            title: '404 Página no encontrada',
            message: 'Favor de validar la url en la barra de direcciones.',
          };
        if (this.error.statusCode === 403)
          return {
            title: '401 No autorizado',
            message: 'Favor de validar sus permisos.',
          };
        return {
          title: `Ha ocurrido un error`,
          message: `Lo sentimos, ha ocurrido un error durante el proceso`,
        };
      },
    },
    methods: {
      routesName(name) {
        const names = {
          'route.home': 'Inicio',
        };
        return names[name] || name;
      },
    },
  };
</script>
