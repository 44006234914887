<template>
  <div class="default relative flex min-h-screen flex-col">
    <Nuxt class="flex-grow bg-worky-white" />
    <ToastComponent />
  </div>
</template>
<script>
  import ToastComponent from '~/components/ui/toast/ToastComponent.vue';
  export default {
    name: 'LoginLayout',
    components: {
      ToastComponent,
    },
    head() {
      const title =
        this.$route.meta.title || this.whiteLabel
          ? 'Nomina precisa y profesional'
          : 'Worky nómina profesional y precisa';
      return {
        title,
      };
    },
  };
</script>
