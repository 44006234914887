import { API_CODES, getErrorMessage } from '~/constants/api';

export default {
  methods: {
    async getData(
      URL,
      inputName,
      showEmptyToast = true,
      showErrorToast = true,
    ) {
      const response = await this.$dataSource.getData(URL);

      return this.handleResponse(
        response,
        inputName,
        showEmptyToast,
        showErrorToast,
      );
    },
    handleResponse(
      response,
      inputName,
      showEmptyToast = true,
      showErrorToast = true,
    ) {
      const { code = 1, data = {} } = response || {};

      if (code === API_CODES?.SUCCESS) return data;

      if (code === API_CODES?.NO_DATA && showEmptyToast) {
        const description = getErrorMessage({
          defaultMessage: `Sin datos de ${inputName}.`,
          response,
        });
        this.$toasts.createToast({
          message: `Sin datos de ${inputName}.`,
          description,
          type: 'alert',
        });
      }

      if (code === API_CODES?.ERROR && showErrorToast) {
        const description = getErrorMessage({
          defaultMessage: 'No se pudo obtener la información.',
          response,
        });
        this.$toasts.createToast({
          message: `No se pudo obtener la información: ${inputName}.`,
          description,
          type: 'error',
        });
      }

      return [];
    },
  },
};
