import { useTerminationAndSettlementStore } from '~~/stores/terminationAndSettlementStore';
export default class Banners {
  constructor() {
    this.terminationAndSettlement = useTerminationAndSettlementStore();
  }

  getNewItem() {
    return this.terminationAndSettlement.getNewItem;
  }

  setNewItem(value) {
    this.terminationAndSettlement.setNewItem(value);
  }
}
