import { defineStore } from 'pinia';

class BannerMessage {
  constructor(type, message, id) {
    this.type = type;
    this.message = message;
    this.id = id;
  }
}

export const useBannerStore = defineStore({
  id: 'banner',
  state: () => ({
    banners: [],
  }),
  getters: {
    getBanners: (state) => {
      return state.banners;
    },
  },
  actions: {
    infoBanner(toast) {
      const { type, message, id } = toast;
      this.banners.push(new BannerMessage(type, message, id));
    },
    removeById(id) {
      const index = this.banners.findIndex((t) => t.id === id);
      this.banners.splice(index, 1);
    },
  },
});
