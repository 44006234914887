var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "toastWrapper fixed z-[11]"
  }, [_c('transition-group', {
    attrs: {
      "name": "transition-toast"
    }
  }, _vm._l(_vm.getToasts, function (toast) {
    return _c('div', {
      key: toast.id,
      staticClass: "toast relative mb-4 flex max-h-92px min-h-72px w-484px items-center gap-16px overflow-hidden rounded-md border-l-[6px] p-4",
      class: `toast-color-${toast.type}`
    }, [_c('div', {
      class: `icon-color-${toast.type}`
    }, [_c('fa', {
      staticClass: "h-32px w-32px fill-current",
      attrs: {
        "icon": ['fas', _vm.icons[toast.type]]
      }
    })], 1), _vm._v(" "), _c('div', {
      staticClass: "flex flex-col"
    }, [_c('h4', {
      staticClass: "text-worky-neutral-600 font-montserrat text-16px font-semibold"
    }, [_vm._v("\n          " + _vm._s(toast.message) + "\n        ")]), _vm._v(" "), _c('p', {
      staticClass: "tooltip-description text-worky-neutral-500 max-h-48px text-12px"
    }, [_vm._v("\n          " + _vm._s(toast.description) + "\n        ")])]), _vm._v(" "), _c('div', {
      staticClass: "closeIcon text-worky-neutral-600 absolute cursor-pointer",
      on: {
        "click": function ($event) {
          return _vm.closeHandler(toast);
        }
      }
    }, [_c('fa', {
      staticClass: "h-20px w-20px fill-current",
      attrs: {
        "icon": "xmark"
      }
    })], 1)]);
  }), 0)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }