import { useAuthenticationStore } from '@/stores/authentication';
const months = {
  1: 'Enero',
  2: 'Febrero',
  3: 'Marzo',
  4: 'Abril',
  5: 'Mayo',
  6: 'Junio',
  7: 'Julio',
  8: 'Agosto',
  9: 'Septiembre',
  10: 'Octubre',
  11: 'Noviembre',
  12: 'Diciembre',
};
export default class Utilities {
  constructor(toasts) {
    this.$auth = useAuthenticationStore();
    this.$toasts = toasts;
  }

  /**
   * Check if the given year is a leap year.
   *
   * @param {number} year - The year to be checked
   * @return {boolean} Whether the given year is a leap year or not
   */
  isLeapYear(year) {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  }

  /**
   * Extract the domain from the given email
   *
   * @param {string} [email=''] - The email from which to extract the domain
   * @return {string} The domain extracted from the given email
   */
  getEmailDomain(email = '') {
    if (!email) return '';
    return email?.split('@')?.at(1)?.split('.')?.at(0) || '';
  }

  /**
   * Removes all properties from the given object which have the value `undefined`.
   * If the given object is not an object, an empty object is returned.
   *
   * @param {Object} obj - The object from which to remove undefined properties
   * @return {Object} The object with undefined properties removed
   */
  removeUndefinedProperties(obj) {
    if (!obj || typeof obj !== 'object') {
      return {};
    }

    return Object.entries(obj).reduce((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = value;
      }
      return acc;
    }, {});
  }

  getTextFromHtml(html, limitChars = 100) {
    const div = document.createElement('div');
    div.innerHTML = html;
    const text = div.textContent || div.innerText || '';
    return text.length > limitChars
      ? `${text.substr(0, limitChars)}...`.trim()
      : text.trim();
  }

  elipsifyText({ text, limitChars = 100 }) {
    if (text && text.length > limitChars) {
      return `${text.slice(0, limitChars)}...`;
    }

    return text;
  }

  isBlob(src) {
    return src instanceof Blob;
  }

  generateNumberRangeString(start, end) {
    const numbers = [];
    for (let i = start; i <= end; i++) {
      numbers.push(i);
    }

    return numbers;
  }

  generateRandomText() {
    return Math.random().toString(36).slice(2);
  }

  formatNotificationsDate(stringDate) {
    const date = new Date(stringDate);
    const getMonthName = (month) => {
      const spanishMonths = [
        'Ene',
        'Feb',
        'Mar',
        'Abr',
        'May',
        'Jun',
        'Jul',
        'Ago',
        'Sep',
        'Oct',
        'Nov',
        'Dic',
      ];
      return spanishMonths[month];
    };

    const monthName = getMonthName(date.getMonth());

    const day = date.getDate().toString().padStart(2, '0');

    const year = date.getFullYear();

    const hour = date.getHours().toString().padStart(2, '0');

    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${monthName} ${day}, ${year} a las ${hour}:${minutes}`;
  }

  formatDateToYYYYMMDD(date) {
    /**
     * Convierte una instancia de Date en un string con formato YYYYMMDD.
     * @param {Date} date - La fecha a convertir.
     * @returns {string} El string con formato YYYYMMDD.
     */
    if (!date) return '';
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}${month}${day}`;
  }

  parseDateFromYYYYMMDD(dateString) {
    /**
     * Convierte un string con formato YYYYMMDD en una instancia de Date.
     * @param {string} dateString - El string con formato YYYYMMDD.
     * @returns {Date} La instancia de Date resultante.
     */
    if (!dateString) return '';
    const year = Number(dateString.slice(0, 4));
    const month = Number(dateString.slice(4, 6)) - 1;
    const day = Number(dateString.slice(6, 8));
    return new Date(year, month, day);
  }

  async fileToBase64(files) {
    const response = {
      success: false,
      response: '',
    };
    await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = () => {
        response.success = true;
        response.response = reader.result;
        resolve(reader.result);
      };
      reader.onerror = (error) => {
        response.success = false;
        response.response = error;
        reject(error);
      };
    });
    return response;
  }

  formatNumber(number) {
    return new Intl.NumberFormat().format(+number);
  }

  truncateNumber(source, decimals) {
    const x = 10 ** decimals;
    let newValue = Math.trunc(+source * x);
    const newValueString = `${newValue}`;
    newValue = Math.trunc(newValue / x);
    let newDecimals = newValueString.substring(
      newValueString.length - decimals,
      newValueString.length,
    );
    while (newDecimals.length < decimals) {
      newDecimals = `0${newDecimals}`;
    }

    return `${newValue}.${newDecimals}`;
  }

  copyToClipboard(
    value,
    title = 'Texto copiado',
    description = 'Se ha copiado el contenido al portapapeles',
  ) {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        const newToast = {
          message: title,
          description,
          type: 'success',
        };

        this.$toasts.createToast(newToast);
      })
      .catch((err) => {
        console.error('Error al copiar', err);
      });
  }

  getEmployeesOnLicense() {
    const { user } = this.$auth.getSession || {};
    if (!user) return 0;
    return user.userconfig_related.find((item) => item.variable === 'employees')
      .value;
  }

  getCurrentUserMail() {
    const { user } = this.$auth.getSession || {};
    if (!user) return 'Usuario Zentric';
    const { email } = user;
    return email;
  }

  getCurrentUserFullName() {
    const { user } = this.$auth.getSession || {};
    if (!user) return 'Usuario Zentric';
    const {
      first_name: firstName,
      last_name: lastName,
      second_surname: secondSurname,
    } = user;

    return secondSurname
      ? `${firstName} ${lastName} ${secondSurname}`
      : `${firstName} ${lastName}`;
  }

  getFullName(name, firstSurname, secondSurname) {
    return secondSurname
      ? `${name} ${firstSurname} ${secondSurname}`.trim().replace(/\s+/g, ' ')
      : `${name} ${firstSurname}`.trim().replace(/\s+/g, ' ');
  }

  getFullDate(val) {
    if (!val) return '';
    const date = val instanceof Date ? val : new Date(val);
    if (!(date instanceof Date) || isNaN(date)) {
      return '';
    }

    const month = `0${date.getMonth() + 1}`.slice(-2);
    const day = `0${date.getDate()}`.slice(-2);
    const year = date.getFullYear();
    const hour = `0${date.getHours()}`.slice(-2);
    const min = `0${date.getMinutes()}`.slice(-2);
    return `${day}-${month}-${year}, ${hour}:${min}`;
  }

  formatDateDDMMYYYY(date) {
    if (!date || date.length < 10) return '';
    try {
      const day = date.substring(8, 10);
      const month = date.substring(5, 7);
      const year = date.substring(0, 4);
      return `${day}-${month}-${year}`;
    } catch (error) {
      return '';
    }
  }

  formatCreateDate(val) {
    if (!val) return '';
    const date = val instanceof Date ? val : new Date(val);
    if (!(date instanceof Date) || isNaN(date)) {
      return '';
    }

    const day =
      `${date.getDate()}`.length === 1 ? `0${date.getDate()}` : date.getDate();
    const month =
      `${+date.getMonth() + 1}`.length === 1
        ? `0${+date.getMonth() + 1}`
        : +date.getMonth() + 1;
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }

  formatSlashDate(date) {
    if (!date) return '';
    const day =
      `${date.getDate()}`.length === 1 ? `0${date.getDate()}` : date.getDate();
    const month =
      `${+date.getMonth() + 1}`.length === 1
        ? `0${+date.getMonth() + 1}`
        : +date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  formatUpdateDate(date) {
    if (!date) return '';
    const day = date.substring(8, 10);
    const month = date.substring(5, 7);
    const year = date.substring(0, 4);
    const finalMonth = +month - 1 < 0 ? 0 : +month - 1;

    return new Date(year, finalMonth, day);
  }

  formatDateToText(date, options = { dateStyle: 'long' }) {
    if (!date) return '';
    return date.toLocaleDateString('es-MX', options);
  }

  formatToPesos(value) {
    if (value === undefined || value === null) return '';
    return new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
    }).format(value);
  }

  formatToFullDate(val) {
    const date = val instanceof Date ? val : new Date(val);
    if (!(date instanceof Date) || isNaN(date)) {
      return val;
    }

    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };

    return new Intl.DateTimeFormat('es-mx', options).format(date);
  }

  formatToDateTime(
    val,
    options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    },
    zone = 'es-mx',
  ) {
    const date = val instanceof Date ? val : new Date(val);
    if (!(date instanceof Date) || isNaN(date)) {
      return val;
    }

    return new Intl.DateTimeFormat(zone, options).format(date);
  }

  // format date to 'DD-MM-YYYY HH_MM
  formatToDateTimeString(date) {
    if (!date) return '';
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    const hour = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${day}-${month}-${year} ${hour}_${minutes}`;
  }

  normalizeString(str) {
    return (
      String(str)
        .normalize('NFD')
        // eslint-disable-next-line unicorn/escape-case
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .trim()
    );
  }

  downloadFile(data, filename) {
    const tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = data;
    tempLink.setAttribute('download', filename);
    tempLink.setAttribute('target', '_blank');

    document.body.appendChild(tempLink);
    tempLink.click();

    setTimeout(() => {
      document.body.removeChild(tempLink);
    }, 200);
  }

  downloadFileFromURL(URL, NAME) {
    const link = document.createElement('a');
    link.download = NAME;
    link.href = URL;
    link.click();
  }

  getWeekDay(date) {
    const options = { weekday: 'short' };
    return new Intl.DateTimeFormat('es-mx', options).format(date);
  }

  formatPercentage(value) {
    return new Intl.NumberFormat('es-MX', {
      style: 'percent',
      minimumFractionDigits: 2,
    }).format(value);
  }

  stringToDate(stringDate) {
    const part = stringDate.split('-');
    return new Date(part[0], Number(part[1] - 1), part[2]);
  }

  monthName(date = new Date()) {
    const options = { month: 'long' };
    return new Intl.DateTimeFormat('es-mx', options).format(date);
  }

  getTextDate(date) {
    // Recibe una fecha en formato '2022-08-01' y retorna '1 de Agosto de 2022'
    if (!date) return '';

    const day = date.substring(8, 10);
    const month = months[+date.substring(5, 7)];
    const year = date.substring(0, 4);
    return `${day} de ${month} de ${year}`;
  }

  dateToDayMonth(date) {
    if (!date) return '';
    const day = date.getDate();
    const month = date.getMonth() + 1;
    // month to monthname
    const monthName = months[month];
    return `${day} de ${monthName}`;
  }

  getTextMonthYear(date) {
    // Recibe una fecha en formato '2022-08-01' y retorna '1 de Agosto de 2022'
    if (!date) return '';

    const month = months[+date.substring(5, 7)];
    const year = date.substring(0, 4);
    return `${month} ${year}`;
  }

  getTextRangeDate(startDate, endDate) {
    // Recibe fecha inicio y fecha fin en formato '2022-08-01' y retorna 'Del 1 de Agosto de 2022 al '15 de Agosto de 2022'
    if (!startDate && !endDate) return '';

    const startDay = startDate.substring(8, 10);
    const endDay = endDate.substring(8, 10);
    const startMonth = months[+startDate.substring(5, 7)];
    const endMonth = months[+endDate.substring(5, 7)];
    const startYear = startDate.substring(0, 4);
    const endYear = endDate.substring(0, 4);
    return startMonth === endMonth && startYear === endYear
      ? `Del ${startDay} al ${endDay} de ${startMonth} de ${startYear}`
      : `Del ${startDay} de ${startMonth} de ${startYear} al ${endDay} de ${endMonth} de ${endYear}`;
  }

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  // fecha en formato yyyy-mm-dd HH:MM
  dateToIsoString(val) {
    const date = val instanceof Date ? val : new Date(val);
    if (!(date instanceof Date) || isNaN(date)) {
      return val;
    }

    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hour = date.getHours();
    const minutes = date.getMinutes();
    return `${year}-${month > 9 ? month : `0${month}`}-${
      day > 9 ? day : `0${day}`
    } ${hour > 9 ? hour : `0${hour}`}:${minutes > 9 ? minutes : `0${minutes}`}`;
  }

  anyStringDateToDate(value) {
    if (value?.length !== 10) return '';
    const date = (value || '').split(/\/|-/);

    const isDDMMYYYY =
      /^([0-2][0-9]|3[0-1])(\/|-)(0[1-9]|1[0-2])\2(\d{4})$/.test(value);

    return isDDMMYYYY
      ? new Date(date[2], date[1] - 1, date[0])
      : new Date(date[0], date[1] - 1, date[2]);
  }

  // obtiene el ambiente de desarrollo si es dev o prod según la url
  // ambientes de desarrollo: localhost, dev y preprod
  getEnvironment() {
    const url = window.location.href;
    if (url.includes('localhost') || url.includes('devapp')) {
      return 'dev';
    } else if (url.includes('preprodapp')) {
      return 'preprod';
    }

    return 'prod';
  }

  formatDateToDB(date) {
    return date && date instanceof Date && !isNaN(date)
      ? date?.toISOString().split('T')[0]
      : null;
  }

  getMonthByNumber(month) {
    return months[month];
  }

  getColorByInitials(initials) {
    const colors = [
      'primary',
      'comp',
      'secondary',
      'tertiary',
      'error',
      'info',
      'success',
    ];
    const code = parseInt(initials, 36);
    return colors[code % colors.length];
  }

  getAvatarImageText(prop1, prop2) {
    if (prop1 && prop2) {
      return `${prop1[0]}${prop2[0]}`;
    }

    const names = prop1.split(' ');
    return names.reduce((acc, name) => acc + name[0], '');
  }
}
