import { useHistoricsStore } from '~/stores/historics.js';
export default class Historics {
  constructor() {
    this.historics = useHistoricsStore();
  }

  getEmployees() {
    return this.historics.getEmployees;
  }

  getHasEmployees() {
    return this.historics.getHasEmployees;
  }

  setEmployees(value) {
    this.historics.setEmployees(value);
  }

  getConcepts() {
    return this.historics.getConcepts;
  }

  getHasConcepts() {
    return this.historics.getHasConcepts;
  }

  setConcepts(value) {
    this.historics.setConcepts(value);
  }

  getPayroll() {
    return this.historics.getPayroll;
  }

  getHasPayroll() {
    return this.historics.getHasPayroll;
  }

  setPayroll(value) {
    this.historics.setPayroll(value);
  }

  getRfc() {
    return this.historics.getRfc;
  }

  getHasRfc() {
    return this.historics.getHasRfc;
  }

  setRfc(value) {
    this.historics.setRfc(value);
  }
}
