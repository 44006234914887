<!-- eslint-disable vue/no-v-html -->
<template>
  <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
  <svg
    :name="icon"
    aria-hidden="true"
    focusable="false"
    role="img"
    v-bind="{ ...$attrs }"
    xmlns="http://www.w3.org/2000/svg"
    class="svg-inline--fa fill-current"
    :viewBox="viewbox"
    v-on="$listeners"
    v-html="iconoSVG"
  />
</template>

<script>
  /*   import light from '~/static/fontawesome/svgs/light.js';
  import solid from '~/static/fontawesome/svgs/solid.js';
  import brands from '~/static/fontawesome/svgs/brands.js';
  import customIcons from '~/static/fontawesome/svgs/custom-icons.js';
  const icons = {
    light,
    solid,
    brands,
    'custom-icons': customIcons,
  }; */
  // DOC: https://blog.fontawesome.com/how-to-use-vue-js-with-font-awesome/
  export default {
    name: 'Fa',
    props: {
      icon: {
        type: [String, Array],
        default: '',
      },
      type: {
        type: String,
        default: 'fal',
      },
    },
    data() {
      return {
        iconPath: {
          fal: 'light',
          far: 'regular',
          fas: 'solid',
          fab: 'brands',
          fad: 'duotone',
          fak: 'custom-icons',
          fafc: 'full-color',
        },
        iconoSVG: '',
        viewbox: '0 0 512 512',
      };
    },
    watch: {
      icon: {
        handler() {
          this.setIcon();
        },
        deep: true,
      },
      type: {
        handler() {
          this.setIcon();
        },
        deep: true,
      },
    },
    mounted() {
      this.setIcon();
    },
    methods: {
      async setIcon() {
        const iconSet = (() => {
          const { icon, type = 'fal' } = this;

          if (Array.isArray(this.icon)) {
            if (this.icon.length > 1) {
              return this.icon;
            }
            const typeSet = type ?? 'fal';
            return [typeSet, ...icon];
          } else {
            return [type, icon];
          }
        })();

        const iconType = iconSet[0];
        const icon = iconSet[1];

        const familyIcon = this.iconPath[iconType];
        if (!familyIcon) {
          throw new Error(`Icon type ${iconType} is not supported`);
        }
        try {
          const family = await import(
            `~/static/fontawesome/svgs/${familyIcon}.json`
          );
          const { icon: iconoSvg, viewbox } = family.default[icon];
          this.iconoSVG = iconoSvg;
          this.viewbox = viewbox;
        } catch (error) {
          console.error(error, familyIcon, icon);
        }
      },
    },
  };
</script>

<style scoped>
  svg {
    overflow: visible;
    box-sizing: content-box;
    display: inline-block;
    overflow: visible;
    vertical-align: -0.125em;
  }
</style>
