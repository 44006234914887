import { defineStore } from 'pinia';

export const useHistoricsStore = defineStore({
  id: 'historics',
  state: () => ({
    employees: [],
    concepts: [],
    payroll: {},
    hasEmployees: false,
    hasConcepts: false,
    hasPayroll: false,
    rfc: {},
    hasRfc: false,
  }),
  getters: {
    getEmployees: (state) => {
      return state.employees;
    },
    getHasEmployees: (state) => {
      return state.hasEmployees;
    },
    getConcepts: (state) => {
      return state.concepts;
    },
    getHasConcepts: (state) => {
      return state.hasConcepts;
    },
    getPayroll: (state) => {
      return state.payroll;
    },
    getHasPayroll: (state) => {
      return state.hasPayroll;
    },
    getRfc: (state) => {
      return state.rfc;
    },
    getHasRfc: (state) => {
      return state.hasRfc;
    },
  },
  actions: {
    setEmployees(value) {
      this.employees = value;
      this.hasEmployees = !!value?.length;
    },
    setConcepts(value) {
      this.concepts = value;
      this.hasConcepts = !!value?.length;
    },
    setPayroll(value) {
      this.payroll = value;
      this.hasPayroll = !!value?.id;
    },
    setRfc(value) {
      this.rfc = value;
      this.hasRfc = !!value?.id;
    },
  },
});
