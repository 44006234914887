import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
var _process, _process$env;
import "core-js/modules/es.object.entries.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
// DOC: https://amplitude.com/docs/sdks/analytics/browser/browser-sdk-2
import * as amplitude from '@amplitude/analytics-browser';
var isProduction = ((_process = process) === null || _process === void 0 ? void 0 : (_process$env = _process.env) === null || _process$env === void 0 ? void 0 : _process$env.ENVIRONMENT) === 'production';
var sendEvent = function sendEvent(_ref) {
  var action = _ref.action,
    category = _ref.category,
    label = _ref.label,
    properties = _ref.properties;
  amplitude.track(action, {
    category: category,
    label: label,
    properties: properties
  });
};
var amplitudeIdentifyUser = function amplitudeIdentifyUser(userId) {
  var userProperties = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  amplitude.setUserId(userId);
  var identifyEvent = new amplitude.Identify();
  Object.entries(userProperties).forEach(function (_ref2) {
    var _ref3 = _slicedToArray(_ref2, 2),
      key = _ref3[0],
      value = _ref3[1];
    identifyEvent.set(key, value);
  });
  amplitude.identify(identifyEvent);
};
export default (function (_ref4, inject) {
  var app = _ref4.app;
  amplitude.init(process.env.AMPLITUDE_API_KEY, {
    autocapture: true
  });
  if (isProduction) {
    inject('amplitude', amplitude);
    inject('sendEvent', sendEvent);
    inject('amplitudeIdentifyUser', amplitudeIdentifyUser);
  } else {
    inject('sendEvent', function (_ref5) {
      var action = _ref5.action,
        category = _ref5.category,
        label = _ref5.label,
        properties = _ref5.properties;
      console.log("AMPLITUDE: Evento registrado en desarrollo: ".concat(action), "Categoria: ".concat(category), "Etiqueta: ".concat(label), "Propiedades: ".concat(properties));
    });
    inject('amplitudeIdentifyUser', function (userId, userProperties) {
      return console.log('AMPLITUDE: Identificación de usuario desactivada en desarrollo.');
    });
  }
});