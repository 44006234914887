export const EMPLOYEES_TAG_EVENTS = {
  CREATED_EMPLOYEE: 'EmployeeAdded',
  DELETED_EMPLOYEE: 'EmployeeDeleted',
  CREATED_PAYROLL_DATA: 'EmployeePayrollDataAdded',
  DELETED_PAYROLL_DATA: 'EmployeePayrollDataDeleted',
  CREATED_PAYROLL_IMSS_DATA: 'EmployeePayrollImssDataAdded',
  DELETED_PAYROLL_IMSS_DATA: 'EmployeePayrollImssDataDeleted',
  CREATED_PAYROLL_MOVEMENT: 'EmployeePayrollMovementAdded',
  DELETED_PAYROLL_MOVEMENT: 'EmployeePayrollMovementDeleted',
  SDI_CALCULATED: 'SdiCalculated',
  CREATED_AFIL_MOV: 'AffiliationMovementsAdded',
  DELETED_AFIL_MOV: 'AffiliationMovementsDeleted',
  SBC_FIXED_CALCULATED: 'SbcFixedCalculated',
};
