import { defineStore } from 'pinia';

export const useCalendarsStore = defineStore({
  id: 'calendars',
  state: () => ({
    _calendarData: '',
  }),
  getters: {
    getCalendarData: (state) => {
      return state._calendarData;
    },
  },
  actions: {
    setCalendarData(value) {
      this._calendarData = value;
    },
  },
});
