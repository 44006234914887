export default {
  methods: {
    logout(redirectPath, isInactiveLogout = false) {
      const isWhiteLabel = localStorage.getItem('white-label') === 'true';

      const redirectURL = redirectPath || this.getLogoutRedirectURL();

      const { email } = this.$authentication.getSession()?.user || {};

      if (!email) {
        this.$authentication.resetSession();
        this.$router.push(redirectURL);
        return {
          successfull: false,
          message: 'Sin datos de sesión',
        };
      }

      const payload = {
        email,
      };

      this.$dataSource.postDataParams(payload, 'users/logout');

      this.showLogoutResultToast({
        isInactiveLogout,
        isWhiteLabel,
      });

      this.$authentication.resetSession();
      this.$router.push(redirectURL);

      return {
        successfull: true,
        message: 'Sesión cerrada',
      };
    },
    getLogoutRedirectURL() {
      const isWhiteLabel = localStorage.getItem('white-label') === 'true';
      const isLayoutAppUser =
        this.$authentication.getUserConfigByVariable('layouts')?.value === '1';
      return isLayoutAppUser
        ? '/layouts-app/login/'
        : isWhiteLabel
          ? '/auth/'
          : '/login/';
    },
    showLogoutResultToast({ isWhiteLabel = false, isInactiveLogout = false }) {
      const title = isInactiveLogout
        ? 'Tu sesión ha caducado'
        : 'Cerraste tu sesión';
      const description = isInactiveLogout
        ? 'Por seguridad, hemos finalizado tu sesión por inactividad.'
        : isWhiteLabel
          ? ''
          : 'Gracias por usar Worky Nómina, esperamos volver a verte pronto.';
      return this.$toasts.createToast({
        message: title,
        description,
        type: 'success',
      });
    },
  },
};
