import { defineStore } from 'pinia';

export const useCartStore = defineStore({
  id: 'cart',
  state: () => ({
    planDetails: null,
    purchaseData: null,
    hasPlan: false,
    hasPurchaseData: false,
  }),
  getters: {
    getPlanDetails: (state) => {
      return state.planDetails;
    },
    getPurchaseData: (state) => {
      return state.purchaseData;
    },
    getHasPlan: (state) => {
      return !!state.planDetails;
    },
    getHasPurchaseData: (state) => {
      return !!state.purchaseData;
    },
  },
  actions: {
    setPlanDetails(value) {
      this.planDetails = value;
    },
    setPurchaseData(value) {
      this.purchaseData = value;
    },
  },
});
