export default class Permissions {
  constructor(authentication, disableRoles = false) {
    this.$authentication = authentication;
    // this.disableRoles = true;
    this.disableRoles = disableRoles;
  }

  hasPermissions(arrayValidPermissions = []) {
    /**
     * Verifica si el usuario tiene al menos uno de los permisos especificados.
     * @param {Array<string>} arrayValidPermissions - Los permisos a verificar.
     * @returns {boolean} Verdadero si el usuario tiene al menos uno de los permisos especificados, de lo contrario falso.
     */

    if (this.disableRoles) return true;
    const sessionUser = this.$authentication.getSession()?.user;
    const isOwner = this.$authentication.isOwner();
    if (isOwner) {
      return true;
    }

    if (!arrayValidPermissions || !arrayValidPermissions?.length) return true;

    const { permission_related: permissions } = sessionUser || {};

    return permissions?.some((item) => arrayValidPermissions?.includes(item));
  }

  /**
   * Verifica si el usuario tiene al menos uno de los permisos especificados.
   * @param {Array<string>} arrayValidPermissions - Los permisos a verificar.
   * @returns {boolean} Verdadero si el usuario tiene al menos uno de los permisos especificados, de lo contrario falso.
   */
  hasPermissionLike(stringValidPermission) {
    if (this.disableRoles) return true;
    const { user: sessionUser = {} } = this.$authentication.getSession() || {};
    const isOwner = this.$authentication.isOwner();
    if (isOwner) {
      return true;
    }

    if (!stringValidPermission) return true;

    const { permission_related: permissions = [] } = sessionUser || {};

    const regex = new RegExp(`^${stringValidPermission}\\.`);
    // Permite que el permiso solicitado coincida solo con el inicio o completamente con alguno de los permisos del usuario
    return (
      permissions?.some((item) => regex.test(item)) ||
      permissions?.some((item) => item === stringValidPermission)
    );
  }
}
