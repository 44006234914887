var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "default relative flex min-h-screen flex-col"
  }, [_c('HeaderCart'), _vm._v(" "), _c('BannerComponent', {
    staticClass: "sticky left-0 right-0 top-[64px] z-8"
  }), _vm._v(" "), _c('Nuxt', {
    staticClass: "bg-worky-background flex-grow"
  }), _vm._v(" "), _c('ToastComponent')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }