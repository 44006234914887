import { usePreferencestore } from '~/stores/preferences';
export default class Preferences {
  constructor() {
    this.preferences = usePreferencestore();
  }

  getIsExpandedMenu() {
    return this.preferences.getIsExpandedMenu;
  }

  getShowRouteOverlay() {
    return this.preferences.getShowRouteOverlay;
  }

  getIsFixedMenu() {
    return this.preferences.getIsFixedMenu;
  }

  toggleMenu() {
    this.preferences.toggleMenu();
  }

  toggleOverlay() {
    this.preferences.toggleOverlay();
  }

  setOverlay(value) {
    this.preferences.setOverlay(value);
  }

  setMenuStateOnHover(value) {
    this.preferences.setMenuStateOnHover(value);
  }
}
