var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "default relative flex min-h-screen"
  }, [_vm.loading ? _c('Loading') : _vm._e(), _vm._v(" "), !_vm.hideZentricCustomization && !_vm.loading ? _c('SideBar', {
    attrs: {
      "is-layouts-app-user": _vm.isLayoutsAppUser
    }
  }) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "relative flex min-h-screen flex-grow flex-col"
  }, [!_vm.hideZentricCustomization && !_vm.loading ? _c('ZHeader', {
    staticClass: "sticky left-0 top-0 z-8",
    attrs: {
      "is-layouts-app-user": _vm.isLayoutsAppUser,
      "is-expanded-menu": _vm.isExpandedMenu
    }
  }) : _vm._e(), _vm._v(" "), _c('BannerComponent', {
    staticClass: "sticky left-0 top-[64px] z-8",
    class: {
      'pl-20': !_vm.hideZentricCustomization
    }
  }), _vm._v(" "), !_vm.loading ? _c('div', {
    staticClass: "h-[calc(100vh - 120px)] grid flex-grow grid-cols-1 overflow-auto bg-worky-background pr-16px transition-all duration-500",
    class: !_vm.isExpandedMenu ? 'pl-96px' : 'pl-[272px]'
  }, [_c('Nuxt', {
    staticClass: "col-span-1"
  })], 1) : _vm._e()], 1), _vm._v(" "), _c('div', [_c('ToastComponent'), _vm._v(" "), _vm.showInactivityModal ? _c('SessionModal', {
    attrs: {
      "timer": _vm.idleTimer,
      "disabled": _vm.validateOnInactivity
    },
    on: {
      "logout": _vm.deleteSession,
      "login": _vm.restoreSession
    }
  }) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }