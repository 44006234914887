import { useToastStore } from '~/stores/toast.js';

export default class Toasts {
  constructor() {
    this.toast = useToastStore();
  }

  getAllToasts() {
    return this.toast.getToasts;
  }

  removeToast(toast) {
    this.toast.removeById(toast);
  }

  createToast(info) {
    info.id = (Math.random() + 1).toString(36).substring(7);
    this.toast.infoToast(info);
    setTimeout(() => {
      this.toast.removeById(info);
    }, 10000);
  }
}
