var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "flex h-[calc(100vh-160px)] items-center justify-center dark:bg-worky-neutral-400"
  }, [_vm.error.statusCode === 403 ? _c('Forbidden') : _c('Error', {
    attrs: {
      "title": _vm.getMessageError.title,
      "message": _vm.getMessageError.message
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }