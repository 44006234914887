<template>
  <div class="default relative flex min-h-screen">
    <Loading v-if="loading" />
    <div v-else class="flex min-h-screen flex-grow flex-col">
      <ZHeader
        :show-search-bar="false"
        :show-nav-bar-helper="false"
        :show-zentric-icon="true"
        :show-user-info="false"
        :show-support="false"
        :show-notifications="false"
        :show-logout="true"
      />
      <Nuxt class="bg-worky-background flex-grow p-[56px]" />
    </div>
    <ToastComponent />
  </div>
</template>

<script>
  import validateAuth from '~/mixins/auth/validateAuth.js';

  export default {
    name: 'DefaultLayout',
    components: {
      Loading: () => import('~/components/ui/loaders/Loading.vue'),
      ToastComponent: () => import('~/components/ui/toast/ToastComponent.vue'),
      ZHeader: () => import('~/components/layout/ZHeader.vue'),
    },
    mixins: [validateAuth],
    middleware: ['sessionData'],
    data() {
      return {
        // Se usa dentro del mixin validateAuth
        loginPath: '/login-tickets/',
        showLostSessionAlert: true,
      };
    },
    computed: {
      menuIsFixed() {
        return this.$preferences.getIsFixedMenu();
      },
    },
    created() {
      this.getOnboardingOverlay();
    },
    methods: {
      getOnboardingOverlay() {},
      setMenuValue(value) {
        if (!this.menuIsFixed) {
          this.$preferences.setMenuStateOnHover(value);
        }
      },
    },
  };
</script>
