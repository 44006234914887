var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "default relative flex min-h-screen"
  }, [_vm.loading ? _c('Loading') : _c('div', {
    staticClass: "flex min-h-screen flex-grow flex-col"
  }, [_c('ZHeader', {
    attrs: {
      "show-search-bar": false,
      "show-nav-bar-helper": false,
      "show-zentric-icon": true,
      "show-user-info": false,
      "show-support": false,
      "show-notifications": false,
      "show-logout": true
    }
  }), _vm._v(" "), _c('Nuxt', {
    staticClass: "bg-worky-background flex-grow p-[56px]"
  })], 1), _vm._v(" "), _c('ToastComponent')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }