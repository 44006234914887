import { defineStore } from 'pinia';

export const usePlatformStore = defineStore({
  id: 'platform',
  state: () => ({
    versionZentric: '',
    loading: false,
  }),
  getters: {
    getVersionZentric: (state) => {
      return state.versionZentric;
    },
    getLoading: (state) => {
      return state.loading;
    },
  },
  actions: {
    setVersionZentric(value) {
      this.versionZentric = value;
    },
    setLoading(value) {
      this.loading = value;
    },
  },
});
