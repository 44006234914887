import { useBannerStore } from '../stores/banner';
export default class Banners {
  constructor() {
    this.banner = useBannerStore();
  }

  getAllBanners() {
    return this.banner.getBanners;
  }

  remove(banner) {
    this.banner.removeById(banner);
  }

  create(info) {
    info.id = (Math.random() + 1).toString(36).substring(7);
    this.banner.infoBanner(info);
  }
}
