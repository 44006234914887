import { useAuthenticationStore } from '@/stores/authentication';

export default class License {
  constructor(dataSource, app) {
    this.$auth = useAuthenticationStore();
    this.$dataSource = dataSource;
    this.$app = app;
  }

  expirationDateValidation() {
    const { user } = this.$auth.getSession || {};
    if (!user)
      return {
        isValid: false,
        redirectPath: 1,
        response: {
          message: 'No se pudo validar la licencia.',
          description:
            'No fue posible obtener datos de la licencia para su validación, por favor intenta más tarde.',
        },
      };

    const { userconfig_related: config } = user || {};
    if (!config)
      return {
        isValid: false,
        redirectPath: 1,
        response: {
          message: 'No se pudo validar la licencia.',
          description:
            'No fue posible obtener datos de la licencia para su validación, por favor intenta más tarde.',
        },
      };

    // Se obtiene la fecha actual en milisegundos
    const currentDate = new Date().getTime();
    // Dentro del config se busca por el nombre de la variable, ya que el orden puede variar, por eso no se usa indices
    const licenseDate = new Date(
      config.find((item) => item.variable === 'expiration')?.value,
    ).getTime();

    const isValid = +licenseDate > +currentDate;

    return {
      isValid,
      response: {
        message: isValid ? +licenseDate - +currentDate : 'Licencia expirada.',
        redirectPath: 2,
        description: isValid
          ? +licenseDate - +currentDate
          : `Tu licencia ha expirado, renueva tu plan para continuar utilizando ${
              this.whiteLabel ? 'la plataforma' : 'Worky'
            }.`,
      },
    };
  }

  async licenseEmployeesValidation() {
    const { user } = this.$auth.getSession || {};
    if (!user)
      return {
        isValid: false,
        redirectPath: 1,
        response: {
          message: 'No se pudo validar la licencia.',
          description:
            'No fue posible obtener datos de la licencia para su validación, por favor intenta más tarde.',
        },
      };

    const { userconfig_related: config } = user || {};
    if (!config)
      return {
        isValid: false,
        redirectPath: 1,
        response: {
          message: 'No se pudo validar la licencia.',
          description:
            'No fue posible obtener datos de la licencia para su validación, por favor intenta más tarde.',
        },
      };

    const endpoint = 'dashboards/license/';
    const specialCases = [
      '/employees/',
      '/idse',
      '/calculations/',
      '/cart',
      '/historic-load',
    ];
    const refreshTime = +localStorage.getItem('last_licence_validation');
    const currentTime = Date.now();

    const { path } = this.$app.context.route;
    const isSpecialCase =
      specialCases.some((item) => path.includes(item)) || path === '/';
    const isTimeValid = currentTime - refreshTime < 60_000;

    if (isTimeValid && !isSpecialCase) {
      return {
        isValid: true,
        response: {
          message: 'Licencia válida.',
          description: 'Licencia válida.',
        },
      };
    }

    const response = await this.$dataSource.getData(endpoint);

    if (response?.code !== 3 && response?.code !== 2) {
      console.error(response);
      return {
        isValid: false,
        redirectPath: 1,
        response: {
          message: 'No se pudo validar la licencia.',
          description:
            'No fue posible obtener los trabajadores registrados en la cuenta, por favor intenta más tarde.',
        },
      };
    }

    const employeesQuantity =
      response.code === 2 ? 0 : +response.data.active_employees;

    const licenseEmployeesQuantity = +response.data.total_employees;

    const isValid = +licenseEmployeesQuantity >= +employeesQuantity;
    if (isValid) {
      localStorage.setItem('last_licence_validation', Date.now());
    } else {
      localStorage.setItem(
        'last_licence_validation',
        Date.now() - 9_000_000_000,
      );
    }

    return {
      isValid,
      response: {
        message: isValid
          ? +licenseEmployeesQuantity - +employeesQuantity
          : 'Licencia sobrepasada.',
        description: isValid
          ? +licenseEmployeesQuantity - +employeesQuantity
          : 'Has sobrepasado la cantidad de trabajadores en tu licencia, por favor incrementa tu plan.',
      },
    };
  }
}
