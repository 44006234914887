var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "default relative flex min-h-screen flex-col"
  }, [_c('Nuxt', {
    staticClass: "flex-grow bg-worky-white"
  }), _vm._v(" "), _c('ToastComponent')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }