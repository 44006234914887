import { useAuthenticationStore } from '@/stores/authentication';

export default async function ({ app, route, store, redirect }) {
  const { path } = route;
  const $auth = useAuthenticationStore();

  const isTicketsApp = path.includes('tickets-app');
  const isLayoutsAppUser =
    app.$authentication.getUserConfigByVariable('layouts')?.value === '1';
  const whiteLabel = localStorage.getItem('white-label') === 'true';

  const redirectPath = isLayoutsAppUser
    ? '/layouts-app/login/'
    : whiteLabel
      ? '/auth/'
      : isTicketsApp
        ? '/login-tickets/'
        : `/login/?redirect=${route.path}`;

  // Se intenta obtener el valor de getIsAuthenticated, el cual deberia estar en true en caso de que el usuario tenga sesion activa
  const isAuthStore = $auth.getIsAuthenticated;
  if (isAuthStore) return;

  // En caso de que en la store no exista sesión:
  // - Se intenta obtener datos de la sesión desde las cookiz
  const session = await app.$cookiz.get('session-zentric');

  // Si no haym entonces se retorna al login
  if (!session) {
    redirect(redirectPath);
    return;
  }

  // Si se tiene sesión en cookiz es neceario validarla, para refrescar sus valores
  const validRefresh = await app.$authentication.refreshToken(session);
  if (!validRefresh) {
    app.$authentication.resetSession();
    redirect(redirectPath);
    return;
  }

  const validUserConfig = await app.$authentication.refreshUserConfig({
    user: session?.user,
    token: validRefresh.token,
  });

  if (!validUserConfig) {
    redirect(redirectPath);
    app.$authentication.resetSession();
    return;
  }

  const newSession = {
    user: {
      ...session?.user,
      userconfig_related: validUserConfig?.userconfig_related || [],
      role: validUserConfig?.role || {},
      permission_related: validUserConfig?.permission_related || [],
      permission_group_set: validUserConfig?.permission_group_set || [],
      is_worky: validUserConfig?.is_worky || false,
    },
    token: validRefresh.token,
    refresh_token: validRefresh.refresh_token,
    support_token: app.$authentication.getSession()?.support_token,
    key: app.$authentication.getSession()?.key,
  };

  app.$authentication.setSession(newSession);
}
