export const REPORTS_TAG_EVENTS = {
  CONSULTED_PAYROLL_REPORT: 'ConsultedReportPayroll',
  DOWNLOADED_PAYROLL_REPORT: 'PayrollReportDownloaded',
  CONSULTED_IMSS_REPORT: 'ConsultedReportIMSS',
  DOWNLOADED_IMSS_REPORT: 'IMSSReportDownloaded',
  CONSULTED_MONTHLY_REPORT: 'ConsultedReportMonthly',
  DOWNLOADED_MONTHLY_REPORT: 'MonthlyReportDownloaded',
  CONSULTED_CLOSING_REPORT: 'ConsultedReportClosing',
  DOWNLOADED_CLOSING_REPORT: 'ClosingReportDownloaded',
};
