export const SUA_TAG_EVENTS = {
  CONSULTED_EMA_EBA: 'ConsultedEMAEBA',
  DOWNLOAD_EMA_EBA: 'EMAEBADownloaded',
  CONSULTED_VARIABILITY: 'ConsultedVariability',
  DOWNLOAD_VARIABILITY: 'VariabilityDownloaded',
  APPLY_VARIABILITY: 'ApplyVariability',
  GENERATE_TXT_FILES: 'GenerateFilesSUA',
  CONSULTED_HISTORICAL_TXT_FILES: 'ConsultedHistoricalSUA',
  DOWNLOAD_HISTORICAL_TXT_FILES: 'FilesDownloadedSUA',
};
