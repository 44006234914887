import { defineStore } from 'pinia';

export const useTerminationAndSettlementStore = defineStore({
  id: 'terminationAndSettlement',

  state: () => ({
    newItem: null,
  }),

  actions: {
    setNewItem(value) {
      this.newItem = value;
    },
  },

  getters: {
    getNewItem() {
      return this.newItem;
    },
  },
});
