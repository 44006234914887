import { defineStore } from 'pinia';

export const useStampingsStore = defineStore({
  id: 'stampings',
  state: () => ({
    periodData: localStorage?.periodData?.length
      ? JSON.parse(localStorage.periodData)
      : {},
    hasPeriodData: !!+localStorage?.hasPeriodData,
  }),
  getters: {
    getPeriodData: (state) => {
      return state.periodData;
    },
    getHasPeriodData: (state) => {
      return state.hasPeriodData;
    },
  },
  actions: {
    setPeriodData(value) {
      this.periodData = value;
      this.hasPeriodData = !!value;
      localStorage.periodData = value ? JSON.stringify(value) : null;
      localStorage.hasPeriodData = value ? 1 : 0;
    },
  },
});
