import { defineStore } from 'pinia';

export const useAuthenticationStore = defineStore({
  id: 'authentication',
  state: () => ({
    session: {},
    isAuthenticated: false,
  }),
  getters: {
    getSession: (state) => {
      return state.session;
    },
    getIsAuthenticated: (state) => {
      return state.isAuthenticated;
    },
  },
  actions: {
    setSession(value) {
      this.session = value;
      this.isAuthenticated = !!value?.token;
    },
    resetSession() {
      this.session = {};
      this.isAuthenticated = false;
    },
  },
});
