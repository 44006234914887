import { usePlatformStore } from '~/stores/platform.js';
export default class Platform {
  constructor() {
    this.platform = usePlatformStore();
  }

  getVersionZentric() {
    return this.platform.getVersionZentric;
  }

  getLoading() {
    return this.platform.getLoading;
  }

  setVersionZentric(value) {
    this.platform.setVersionZentric(value);
  }

  setLoading(value) {
    this.platform.setLoading(value);
  }
}
