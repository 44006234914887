import { useAuthenticationStore } from '@/stores/authentication';
const validatePath = ({ path, redirect, isLayoutsAppUser }) => {
  const whiteLabelNotAllowed = [
    '/cart',
    '/tickets',
    '/complaints-requests',
    '/helps',
    '/andromeda',
    '/saggita',
  ];

  const layoutsAppAllowed = ['/layouts-app', '/404'];

  const unauthorizedLayoutsApp =
    isLayoutsAppUser &&
    !layoutsAppAllowed.some((allowed) => path.startsWith(allowed));
  const unauthorizedWL =
    localStorage.getItem('white-label') === 'true' &&
    whiteLabelNotAllowed.some((p) => path.startsWith(p));

  if (unauthorizedLayoutsApp || unauthorizedWL) {
    return {
      isValid: false,
      redirectPath: '/404/',
    };
  }

  return {
    isValid: true,
    redirectPath: '',
  };
};

export default async function ({ app, route, store, redirect }) {
  const $auth = useAuthenticationStore();

  const isAuthStore = $auth.getIsAuthenticated;
  const session = await app.$cookiz.get('session-zentric');

  const { path, query, hash } = route;
  const isLayoutsAppUser =
    app.$authentication.getUserConfigByVariable('layouts')?.value === '1';
  const validation = validatePath({ path, redirect, isLayoutsAppUser });

  if (!validation.isValid && (isAuthStore || session)) {
    redirect(validation.redirectPath);
    return;
  }

  const splittedPath = path.split('?|#');
  const hasTrailingSlash = /\/+$/.test(splittedPath.at(0));
  if (hasTrailingSlash) return;

  const nextPath = path.replace(splittedPath.at(0), `${splittedPath.at(0)}/`);
  const nextRoute = { path: nextPath, query, hash };
  const hasMatch = app.router.matcher.match(nextRoute).name;
  if (!hasMatch) return;
  // 308 Permanent Redirect
  return redirect(nextRoute);
}
