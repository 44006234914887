/**
 * Middleware para verificar si el usuario tiene permiso para acceder a una ruta.
 * @param {Object} context - El contexto de la aplicación Nuxt.
 * @param {Function} context.app - El objeto de la aplicación Nuxt.
 * @param {Function} context.redirect - La función de redirección de Nuxt.
 * @param {Object} context.route - La ruta actual de Nuxt.
 */

// Para el atributo de los permisos se usan los siguientes:
// - Create
// - Read
// - Update
// - Delete
// - BulkUpdate
// - BulkCreate

export default function ({ app, redirect, route, store, env, error }) {
  const { pathPermissions = null, hidden = false } = route.meta?.at(-1) || {};

  // If is a hidden page we don't check permissions
  if (hidden) {
    error({ statusCode: 404, message: '' });
    return;
  }

  const { permissions = '' } = pathPermissions || {};

  const permissiosIsArray = Array.isArray(permissions);

  // If there is no permissions defined we allow access
  if (!pathPermissions || (permissiosIsArray && !permissions.length)) {
    return;
  }

  // If it's an owner we allow access
  const isOwner = app.$authentication?.isOwner();
  if (isOwner) {
    return;
  }

  // If it's an staff account and a staff view we allow access
  const staffAccount = app.$authentication.isStaff();
  const { support_token: isControlledAccount } =
    app.$authentication.getSession() ?? {};
  const { onlyStaff = false } = pathPermissions || {};

  if (onlyStaff && (staffAccount || isControlledAccount)) {
    return;
  }

  let isAllowed = false;

  if (onlyStaff && !staffAccount && !isControlledAccount) {
    isAllowed = false;
  } else if (permissiosIsArray) {
    isAllowed = app.$permissions.hasPermissions(pathPermissions.permissions);
  } else {
    const { param = '' } = pathPermissions || {};
    const { update = '', create = '' } = permissions || {};

    const isForm = !!update || !!create;
    const isUpdate = !!route.params[param];
    const formPermission = isUpdate ? update : create;
    const hasFormPermission = app.$permissions.hasPermissions(formPermission);

    const hasListPermission = app.$permissions.hasPermissionLike(permissions);

    isAllowed = isForm ? hasFormPermission : hasListPermission;
  }

  if (isAllowed) {
    return;
  }

  const { redirectUrl = '' } = pathPermissions || {};

  if (redirectUrl) {
    redirect(redirectUrl);
    return;
  }

  error({ statusCode: 403, message: 'Forbidden' });
}
