import { extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

import getPostalCodeId from '~/mixins/forms/getPostalCodeId';

// Register it globally
export default {
  mixins: [getPostalCodeId],
  mounted() {
    extend('required', {
      ...required,
      message: 'Campo requerido.',
    });

    extend('email', {
      computesRequired: true,
      validate: this.$formValidations.isValidEmail,
      message: 'El formato de correo es incorrecto.',
    });

    extend('isEmailOrEmpty', {
      computesRequired: true,
      validate: this.$formValidations.isValidEmailOrEmpty,
      message: 'El formato de correo es incorrecto.',
    });

    extend('isExternalNumber', {
      computesRequired: true,
      validate: this.$formValidations.isValidExternalNumber,
      message: 'Número exterior solo admite 14 caracteres (A-Z 0-9 #&:;°.,+)',
    });

    extend('isExternalNumberOrEmpty', {
      computesRequired: true,
      validate: this.$formValidations.isValidExternalNumberOrEmpty,
      message: 'Número exterior solo admite 14 caracteres (A-Z 0-9 #&:;°.,+)',
    });

    extend('isText', {
      computesRequired: true,
      validate: this.$formValidations.isValidText,
      message: 'Ingresa solo letras.',
    });

    extend('isTextOrEmpty', {
      computesRequired: true,
      validate: this.$formValidations.isValidTextOrEmpty,
      message: 'Ingresa solo letras.',
    });

    extend('isRfc', {
      computesRequired: true,
      validate: this.$formValidations.isValidRfc,
      message: 'Formato de RFC inválido.',
    });

    extend('isCurp', {
      computesRequired: true,
      validate: this.$formValidations.isValidCurp,
      message: 'Formato de CURP inválido.',
    });

    extend('onlyNumbers', {
      computesRequired: true,
      validate: this.$formValidations.hasOnlyNumbers,
      message: 'Ingresa solo números.',
    });

    extend('isExpiration', {
      computesRequired: true,
      validate: this.$formValidations.isExpirationFormat,
      message: 'Formato de fecha incorrecto (mm/aa).',
    });

    extend('isMobile', {
      computesRequired: true,
      validate: this.$formValidations.isValidMobile,
      message: 'Número de celular inválido.',
    });

    extend('isMobileOrEmpty', {
      computesRequired: true,
      validate: this.$formValidations.isValidMobileOrEmpty,
      message: 'Número de celular inválido.',
    });

    extend('noSpaces', {
      computesRequired: true,
      validate: this.$formValidations.notSpaces,
      message: 'El formato de texto es incorrecto.',
    });

    extend('isEmployerRegistry', {
      computesRequired: true,
      validate: this.$formValidations.isValidEmployerRegistry,
      message: 'Formato de Registro Patronal inválido.',
    });

    extend('isClabe', {
      computesRequired: true,
      validate: this.$formValidations.isValidClabe,
      message: 'Formato de CLABE inválido.',
    });

    extend('isBankAccount', {
      computesRequired: true,
      validate: this.$formValidations.isValidBankAccount,
      message: 'Formato de cuenta Bancaria inválido.',
    });

    extend('isCardNumber', {
      computesRequired: true,
      validate: this.$formValidations.isValidCardNumber,
      message: 'Formato de número de tarjeta inválido.',
    });

    extend('isClabeOrEmpty', {
      computesRequired: true,
      validate: this.$formValidations.isValidClabeOrEmpty,
      message: 'Formato de CLABE inválido.',
    });

    extend('isBankAccountOrEmpty', {
      computesRequired: true,
      validate: this.$formValidations.isValidBankAccountOrEmpty,
      message: 'Formato de cuenta Bancaria inválido.',
    });

    extend('isCardNumberOrEmpty', {
      computesRequired: true,
      validate: this.$formValidations.isValidCardNumberOrEmpty,
      message: 'Formato de número de tarjeta inválido.',
    });

    extend('isCvc', {
      computesRequired: true,
      validate: this.$formValidations.isValidCvc,
      message: 'Formato de CVC inválido.',
    });

    extend('isDecimalNumber', {
      computesRequired: true,
      validate: this.$formValidations.isValidDecimalNumber,
      message: 'Solo admite números con 2 a 6 decimales.',
    });

    extend('isPercentageOrEmpty', {
      computesRequired: true,
      validate: this.$formValidations.isValidPercentageOrEmpty,
      message:
        'Formato inválido, debe ser un porcentaje valido entre 0% y 100%.',
    });

    extend('isTextSpaceNumber', {
      computesRequired: true,
      validate: this.$formValidations.isValidTextSpaceNumber,
      message: 'Solo admite letras, números y espacios.',
    });

    extend('isTextSpaceNumberOrEmpty', {
      computesRequired: true,
      validate: this.$formValidations.isValidTextSpaceNumberOrEmpty,
      message: 'Solo admite letras, números y espacios.',
    });

    extend('isTextNumber', {
      computesRequired: true,
      validate: this.$formValidations.isValidTextNumber,
      message: 'Solo admite letras y/o números.',
    });

    extend('isTextNumbersSpacesSpecialChars', {
      computesRequired: true,
      validate: this.$formValidations.isValidTextNumbersSpacesSpecialChars,
      message:
        'Solo admite letras, números, espacios y caracteres especiales (#¿?¡!@$%^&+-_ .,)',
    });

    extend('isKey', {
      computesRequired: true,
      validate: this.$formValidations.isValidKey,
      message:
        'Longitud de 3 a 5 caracteres alfanuméricos y/o caracteres especiales (#¿?¡!@$%^& () +-_.,)',
    });

    extend('isSsn', {
      computesRequired: true,
      validate: this.$formValidations.isValidSsn,
      message: 'Formato de NSS inválido.',
    });

    extend('numberOrEmpty', {
      computesRequired: true,
      validate: this.$formValidations.isValidNumberOrEmpty,
      message: 'Solo permite números o vacío.',
    });

    extend('isFormula', {
      computesRequired: true,
      validate: this.$formValidations.isValidFormula,
      message:
        'Solo admite letras, números, espacios y caracteres especiales (#¿?¡!@$%^=*/&+-_ .)',
    });

    extend('isAmount', {
      computesRequired: true,
      validate: this.$formValidations.isValidAmount,
      message: 'Solo admite números y decimales.',
    });

    extend('isName', {
      computesRequired: true,
      validate: this.$formValidations.isValidName,
      message: 'Solo mayúsculas, espacios y punto.',
    });

    extend('isNameOrEmpty', {
      computesRequired: true,
      validate: this.$formValidations.isValidNameOrEmpty,
      message: 'Solo admite mayúsculas, espacios y punto o vacío.',
    });

    extend('isEmployeeName', {
      computesRequired: true,
      validate: this.$formValidations.isValidEmployeeName,
      message: 'Solo admite mayúsculas, espacios, punto y comilla simple.',
    });

    extend('isEmployeeNameOrEmpty', {
      computesRequired: true,
      validate: this.$formValidations.isValidEmployeeNameOrEmpty,
      message:
        'Solo admite mayúsculas, espacios, punto y comilla simple o vacío.',
    });

    extend('isPercentage', {
      computesRequired: true,
      validate: this.$formValidations.isValidPercentage,
      message:
        'Formato inválido, debe contener un número entero entre 0% y 100%.',
    });

    extend('isRiskValue', {
      computesRequired: true,
      validate: this.$formValidations.isValidRiskValue,
      message:
        'Formato inválido, debe contener entre 1 a 6 enteros y 1 a 12 decimales.',
    });

    extend('isAccountingAccount', {
      computesRequired: true,
      validate: this.$formValidations.isValidAccountingAccount,
      message:
        'Formato de cuenta contable invalido, solo puede contener guiones, espacios, números y letras (excepto con tildes y ñ)',
    });

    extend('isLengthService', {
      computesRequired: true,
      validate: this.$formValidations.isValidLengthService,
      message: 'Solo admite números con 1-2 enteros y 1-3 decimales.',
    });

    extend('isVacationAllowance', {
      computesRequired: true,
      validate: this.$formValidations.isValidVacationAllowances,
      message: 'Solo admite números con 1 entero y 1-4 decimales.',
    });

    extend('isIntegratingFactor', {
      computesRequired: true,
      validate: this.$formValidations.isValidIntegratingFactor,
      message: 'Solo admite números con 1 entero y 1-6 decimales.',
    });

    extend('isAccountName', {
      computesRequired: true,
      validate: this.$formValidations.isValidAccountName,
      message:
        'Formato inválido, debe iniciar con mayúscula y solo incluir letras y/o espacio.',
    });

    extend('isAccountNameOrEmpty', {
      computesRequired: true,
      validate: this.$formValidations.isValidAccountNameOrEmpty,
      message:
        'Formato inválido, debe iniciar con mayúscula y solo incluir letras y/o espacio o ser vacío.',
    });

    extend('requiredDate', {
      computesRequired: true,
      validate: (value) => {
        if (value == null || value === '' || `${value}` === 'Invalid Date') {
          return false;
        }

        return true;
      },
      message: 'La fecha es requerida',
    });

    extend('requiredObject', {
      computesRequired: true,
      validate: (value) => {
        if (
          value == null ||
          value === '' ||
          Array.isArray(value) ||
          (typeof value === 'object' && Object.keys(value ?? {}).length === 0)
        ) {
          return false;
        }

        return true;
      },
      message: 'Seleccione una opción',
    });

    extend('requiredArray', {
      computesRequired: true,
      validate: (value) => {
        if (value == null || value === '' || value.length === 0) {
          return false;
        }

        return true;
      },
      message: 'seleccione una o mas opciones',
    });

    extend('requiredPassword', {
      ...required,
      message: 'Contraseña es requerida',
    });

    extend('validatePassword', {
      params: ['target'],
      validate(value, { target }) {
        return value === target;
      },
      message: 'Las contraseñas no coinciden',
    });

    extend('validateClabe', {
      params: ['target'],
      validate(value, { target }) {
        return !value || value.startsWith(target.key)
          ? true
          : `La CLABE debe iniciar con ${target.key}`;
      },
    });

    extend('validateUpdateId', {
      params: ['ids'],
      validate(value, { ids }) {
        Array.isArray(ids) || (ids = [ids]);
        return !!value && ids.some((id) => +id === +value.split('-')?.at(0));
      },
      message: 'ID no encontrado',
    });

    extend('isValidFormat', {
      validate: this.$formValidations.isValidPassword,
      message: 'Contraseña inválida',
    });

    extend('customError', {
      computesRequired: true,
      params: ['error'],
      validate(value, { error }) {
        return error;
      },
    });
    extend('isPostalCode', {
      computesRequired: true,
      validate: async (value) => {
        if (this.$formValidations.isValidPostalCode(value)) {
          const postalCode = await this.getPostalCodeId(value);
          if (postalCode === 0 && value !== '') {
            return 'Código postal no encontrado.';
          }

          return true;
        } else {
          return 'Formato de código postal inválido.';
        }
      },
      message: 'Formato de código postal inválido.',
    });

    extend('isPostalCodeNotZero', {
      computesRequired: true,
      validate: async (value) => {
        if (
          this.$formValidations.isValidPostalCode(value) &&
          String(value) !== '00000'
        ) {
          const postalCode = await this.getPostalCodeId(value);
          if (postalCode === 0 && value !== '') {
            return 'Código postal no encontrado.';
          }

          return true;
        } else {
          return 'Formato de código postal inválido.';
        }
      },
      message: 'Formato de código postal inválido.',
    });

    extend('isPostalCodeOrEmpty', {
      computesRequired: true,
      validate: async (value) => {
        if (this.$formValidations.isValidPostalCodeOrEmpty(value)) {
          if (value === '') {
            return true;
          }

          const postalCode = await this.getPostalCodeId(value);
          if (postalCode === 0 && value !== '') {
            return 'Código postal no encontrado.';
          }

          return true;
        } else {
          return 'Formato de código postal inválido.';
        }
      },
      message: 'Formato de código postal inválido.',
    });
  },
};
