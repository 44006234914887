/**
 * Gets the error message from the API response.
 *
 * @param {{ defaultMessage: string, errors: any, message: string, detail: string }} options
 * @returns {string}
 */
export const getErrorMessage = ({ defaultMessage, response }) => {
  const { errors = [], message = '', detail = '' } = response || {};
  if (message) return message;

  if (detail) return detail;

  const errorMessageIfIsArray =
    errors?.at(0)?.message || errors?.at(0)?.detail || errors?.at(0) || '';
  const errorMessageIfIsObject =
    errors?.message || errors?.detail || errors || '';

  return errorMessageIfIsArray || errorMessageIfIsObject || defaultMessage;
};
