// polifill for scrollIntoViewIfNeeded
// source: https://github.com/nuxodin/lazyfill/blob/main/polyfills/Element/prototype/scrollIntoViewIfNeeded.js
if (!Element.prototype.scrollIntoViewIfNeeded) {
  Element.prototype.scrollIntoViewIfNeeded = function (centerIfNeeded = true) {
    const el = this;
    new IntersectionObserver(function ([entry]) {
      const ratio = entry.intersectionRatio;
      if (ratio < 1) {
        const place = ratio <= 0 && centerIfNeeded ? 'center' : 'nearest';
        el.scrollIntoView({
          block: place,
          inline: place,
        });
      }
      this.disconnect();
    }).observe(this);
  };
}
