var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', _vm._g(_vm._b({
    staticClass: "svg-inline--fa fill-current",
    attrs: {
      "name": _vm.icon,
      "aria-hidden": "true",
      "focusable": "false",
      "role": "img",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": _vm.viewbox
    },
    domProps: {
      "innerHTML": _vm._s(_vm.iconoSVG)
    }
  }, 'svg', Object.assign({}, _vm.$attrs), false), _vm.$listeners));

}
var staticRenderFns = []

export { render, staticRenderFns }