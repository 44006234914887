export default {
  data() {
    return {
      validateAuthInterval: null,
      loading: true,
      validateAuthIntervalTime: 5 * 60 * 1000,
    };
  },
  created() {
    // Duración de token (TODOS LOS AMBIENTES):
    // - Auth: 15 minutos
    // - Refresh: 20 min
    this.validateAuthInterval = setInterval(() => {
      this.validateAuth();
    }, this.validateAuthIntervalTime);
    this.loading = false;
  },
  beforeDestroy() {
    if (!this.validateAuthInterval) return;
    clearInterval(this.validateAuthInterval);
  },
  methods: {
    validateAuth() {
      const session = this.$authentication.getSession();
      if (session) {
        return this.validateCurrentSession(session);
      }

      this.cleanSessionData();
    },
    async validateCurrentSession(session) {
      const validRefresh = await this.$authentication.refreshToken(session);
      if (!validRefresh) {
        this.cleanSessionData();
        return;
      }

      const validUserConfig = await this.$authentication.refreshUserConfig({
        user: session?.user,
        token: validRefresh.token,
      });
      if (!validUserConfig) {
        this.cleanSessionData();
        return;
      }

      const newSession = {
        user: {
          ...session?.user,
          userconfig_related: validUserConfig?.userconfig_related || [],
          role: validUserConfig?.role || {},
          permission_related: validUserConfig?.permission_related || [],
          permission_group_set: validUserConfig?.permission_group_set || [],
          is_worky: validUserConfig?.is_worky || false,
        },
        token: validRefresh.token,
        refresh_token: validRefresh.refresh_token,
        support_token: this.$authentication.getSession()?.support_token,
        key: this.$authentication.getSession()?.key,
      };

      this.setNewSession(newSession);
    },
    setNewSession(session) {
      this.$authentication.setSession(session);
    },
    cleanSessionData() {
      this.$authentication.resetSession();

      if (this.showLostSessionAlert) {
        this.$toasts.createToast({
          message: 'Has cerrado sesión.',
          description: 'Tu sesión ha expirado',
          type: 'error',
        });
      }

      this.$router.push(this.loginPath);
    },
  },
};
