import { useAuthenticationStore } from '@/stores/authentication';
import { API_CODES } from '~/constants/api';

export default async function ({ app, route, store, redirect }) {
  const { hidden = false } = route.meta?.at(-1) || {};
  const $auth = useAuthenticationStore();
  if (hidden) {
    redirect('/login/');
    return;
  }

  const baseUrl = process.env.DATA_SOURCE;

  // Se obtiene la ruta a la que se quiere acceder
  const { path } = route;
  const redirectOptions = {
    '/login/': '/',
    '/auth/': '/',
    '/layouts-app/login/': '/layouts-app/',
    '/login-cart/': '/cart-app/payment-process/',
    '/login-tickets/': '/tickets-app/',
  };

  const loginPaths = Object.keys(redirectOptions);
  if (loginPaths.includes(path) && path.includes('auth')) {
    localStorage.setItem('white-label', true);
  } else {
    localStorage.setItem('white-label', false);
  }

  // Se obtiene el valor de la store
  if (
    route.query.token &&
    route.query.refresh_token &&
    route.query.userid &&
    route.query.redirect
  ) {
    app.$authentication.resetSession();

    const validOrigins = /.*/;
    const hideCustomizationOn = /((app|qa|staging)\.worky.mx|localhost)/;

    const apiKey = route.query.token;
    const refresh = route.query.refresh_token;
    const { userid } = route.query;
    const redirectUrl = route.query.redirect ?? '/';

    const { referrer } = document;
    const hideZentricCustomization = hideCustomizationOn.test(referrer);

    if (
      validOrigins.test(referrer) &&
      redirectUrl &&
      apiKey &&
      refresh &&
      userid
    ) {
      const loginData = {
        token: apiKey,
        refresh_token: refresh,
      };

      $auth.setSession(loginData);

      let response = {};
      try {
        const url = `${baseUrl}users/users/${userid}/`;
        response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWT ${apiKey}`,
          },
        }).then((res) => res.json());
      } catch (error) {
        app.$authentication.resetSession();
        redirect(path);
      }

      if (response.code === API_CODES.SUCCESS) {
        const { data } = response;
        $auth.setSession({});
        const sessionData = {
          ...loginData,
          user: data,
          hideZentricCustomization,
        };

        app.$authentication.setSession(sessionData);

        redirect(redirectUrl);
      } else {
        app.$authentication.resetSession();
        redirect(path);
      }
    }

    return;
  }

  const isAuthStore = $auth.getIsAuthenticated;
  if (!isAuthStore) {
    // En caso de que en la store no exista autenticacion
    // -Se intenta obtener sesión desde cookiz
    const session = app.$cookiz.get('session-zentric');
    if (session) {
      // Actualizamos la store con la sesión obtenido de cookiz

      const redirectUrl = redirectOptions[path];
      redirect(redirectUrl);
    }
  }
}
